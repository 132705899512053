<template>
  <div id="app">
    <Home></Home>
  </div>
</template>

<script>
import Home from './views/home/home'
export default {
  name: 'App',
  components: { Home }
}
</script>
<style lang="scss">
html,
body,
#app {
  height: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  margin: 0 auto;
}
</style>
