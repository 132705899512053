<template>
    <div class="download-carousel">
      <el-carousel
        class="download-carousel-box"
        indicator-position="outside"
        arrow="never"
        trigger="click"
        :interval="2000"
      >
        <el-carousel-item class="download-carousel-item">
          <img class="download-carousel-item__img" src="../../assets/huiben.png" alt="叫叫绘本" />
        </el-carousel-item>
        <el-carousel-item class="download-carousel-item">
          <img class="download-carousel-item__img" src="../../assets/shizi.png" alt="叫叫识字" />
        </el-carousel-item>
        <el-carousel-item class="download-carousel-item">
          <img class="download-carousel-item__img" src="../../assets/kousuan.png" alt="叫叫口算" />
        </el-carousel-item>
      </el-carousel>
    </div>
</template>

<script>
import { Carousel, CarouselItem } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

export default {
  name: 'DownloadBanner',
  components: {
    'el-carousel': Carousel,
    'el-carousel-item': CarouselItem
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/common.scss';

@mixin resize($scale) {
  .download-carousel {
    width: 1050px;
    margin: 0 auto;
    margin-bottom: 180px;

    .download-carousel__title {
      height: 50px;
      margin-bottom: 60px;
    }
    .download-carousel-box {
      width: 1050px;
      height: 615px;

      .download-carousel-item__img {
        max-width: 1050px;
        height: 540px;
      }

      ::v-deep .el-carousel__container {
        height: 100% !important;
      }
      ::v-deep .el-carousel__indicators--outside {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      ::v-deep .el-carousel__indicator--horizontal {
        padding: 0;
      }
      ::v-deep .el-carousel__button {
        width: 15px;
        height: 15px;
        padding: 0;
        margin: 0 12.5px;
        border-radius: 50%;
      }
      ::v-deep .el-carousel__indicator button {
        background-color: #f4f4f4;
        opacity: 1;
      }
      ::v-deep .el-carousel__indicator.is-active button {
        background-color: #502e2e;
        opacity: 1;
      }
    }
  }
}
@include resize(1);
</style>
