<template>
  <div class="home-container">
    <div v-if="liankousuan">
      <section>
        <h2 class="title">叫叫练口算</h2>
        <div class="imgBox">
          <img src="../../assets/four.png" alt="" />
        </div>
      </section>
    </div>
    <div v-else>
      <section>
        <h2 class="title">叫叫识字</h2>
        <div class="imgBox">
          <img src="../../assets/one.png" alt="" />
        </div>
      </section>
      <section>
        <h2 class="title">叫叫绘本</h2>
        <div class="imgBox">
          <img src="../../assets/two.png" alt="" />
        </div>
      </section>
      <section>
        <h2 class="title">叫叫口算</h2>
        <div class="imgBox">
          <img src="../../assets/three.png" alt="" />
        </div>
      </section>
      <section>
        <h2 class="title">下载中心</h2>
        <div class="appDownloadBox">
          <Banner />
        </div>
      </section>
    </div>
    <div>
      <Footer />
    </div>
  </div>
</template>
<script lang="ts">
import Banner from './banner.vue'
import Footer from './footer.vue'
export default {
  components: {
    Banner,
    Footer
  },
  computed: {
    // 获取当前域名
    currentHost() {
      return location.host
    },
    // 判断当前域名是否为 南京铁皮人叫叫练口算
    liankousuan() {
      return this.currentHost === 'tinmannj.com' || this.currentHost === 'www.tinmannj.com'
    }
  }
}
</script>

<style lang="scss" scoped>
@import './home.scss';
</style>
