import 'lib-flexible'
import App from './App.vue'
import Vue from 'vue'
import { Swipe, SwipeItem } from 'vant'

Vue.use(Swipe).use(SwipeItem)
Vue.config.productionTip = false

new Vue({
  render: (h) => h(App)
}).$mount('#app')
